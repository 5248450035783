import $ from "jquery";
import "../../styles/global.less";
import "../../styles/reset.less";
import "./index.less";
import "./media.less";

var swiper;

$(document).ready(function () {
  // 滚动条距离顶部距离 ========
  var scrollTop = 0;

  setRemFontSize();

  window.addEventListener("resize", function () {
    setRemFontSize();
  });


  window.handleClickSwiper = function (direction) {
    if (direction === 'prev') {
      swiper.slidePrev(); // 切换到上一个滑块
    } else if (direction === 'next') {
      swiper.slideNext(); // 切换到下一个滑块
    }
  };

  var currentIndex = 0;
  var timeListDoms = $(".time-list li");

  $(".banner-title").addClass("banner-title-active");
  $(".banner-slogen").addClass("banner-slogen-active");

  swiper = new Swiper(".mySwiper", {
    direction: "vertical",
    autoplay: {
      delay: 3000,
    },
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      slideChange() {
        currentIndex = this.realIndex;

        timeListDoms.removeClass("active");
        timeListDoms.each(function (index, item) {
          const round = $(item).find('.round');
          const roundLine = $(item).find('.round_line');
          round.removeClass('scale'); // 移除所有圆点的放大效果

          if (currentIndex === index) {
            $(item).addClass("active");
            round.css('background-color', '#01579b'); // 设置当前圆点颜色
            roundLine.css('border-color', '#01579b'); // 设置当前线条颜色
            round.addClass('scale'); // 添加放大效果

            // 1秒后移除放大效果
            setTimeout(() => {
              round.removeClass('scale');
            }, 1000);
          } else {
            round.css('background-color', '#a1a1a1'); // 其他圆点恢复颜色
            roundLine.css('border-color', '#a1a1a1'); // 其他线条恢复颜色
          }
        });
      },
    }

  });

  // 获取每一块距离顶部的高度

  const aboutUsTop = document.getElementById("about-us").offsetTop;
  const developTop = document.getElementById("develop").offsetTop;
  const cooperateTop = document.getElementById("cooperate").offsetTop;
  const solutionTop = document.getElementById("solution").offsetTop;
  const footerTop = document.getElementById("footer").offsetTop;

  $(window).scroll(function () {
    scrollTop = $(document).scrollTop();
    const headerListDoms = $(".test");
    function removeClassDom() {
      headerListDoms.each((index, item) => {
        $(item).removeClass("active");
      });
    }

    if (scrollTop === 0) {
      removeClassDom()
      $(headerListDoms[0]).addClass("active");
    }
    if (scrollTop >= aboutUsTop - 1 && scrollTop < developTop - 1) {
      removeClassDom()
      $(headerListDoms[1]).addClass("active");
    }

    if (scrollTop >= developTop - 1 && scrollTop < cooperateTop - 1) {
      removeClassDom()
      $(headerListDoms[2]).addClass("active");
    }

    if (scrollTop >= cooperateTop - 1 && scrollTop < solutionTop - 1) {
      removeClassDom()
      $(headerListDoms[3]).addClass("active");
    }

    if (scrollTop >= solutionTop - 1 && scrollTop < footerTop - 1) {
      removeClassDom()
      $(headerListDoms[4]).addClass("active");
    }

    if (scrollTop >= 2400) {
      removeClassDom()
      $(headerListDoms[5]).addClass("active");
    }

    var difference = scrollTop - 500;
    var topOpacity = 0;

    if (difference > 70) {
      topOpacity = 0;
    }

    if (difference > 0 && difference < 70) {
      topOpacity = difference / 70;
    }

    if (difference >= 70) {
      topOpacity = 1;
    }

    $("#topHeader").css(
      "background-color",
      "rgba(1, 84, 155," + topOpacity + ")"
    );
  });
  const windowUrl = window.location.href
  // const parmas = new URLSearchParams(windowUrl)
  // 使用URL对象解析URL（现代浏览器支持）
  var urlObject = new URL(windowUrl);

  // 获取哈希部分（#后面的内容）
  var hash = urlObject.hash;

  // 去掉哈希符号#，获取纯内容
  var hashContent = hash.replace('#', '');
  if (hashContent === 'footer') {
    window.scrollTo(0, 3000)
  }
});

window.setRemFontSize = function setRemFontSize() {
  var wsize = window.innerWidth;
  var size = wsize > 1240 ? wsize / 10 : 1240 / 10;
  document.documentElement.style.fontSize = size + "px";
};
